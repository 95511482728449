import React from "react";
import TypeWriter from "typewriter-effect";

const UtilizeOurTypeWriter = () => (
  <div style={{display: "inline", color: "#ff4338"}}>
    <TypeWriter
      options={{loop: true, cursor: ""}}
      onInit={(typewriter) => {
        typewriter

          .changeDelay(150)
          .typeString("time")
          .pauseFor(1500)
          .deleteAll()
          .typeString("network")
          .pauseFor(1500)
          .deleteAll()
          .typeString("energy")
          .pauseFor(1500)
          .deleteAll()
          .typeString("experience")
          .pauseFor(1500)
          .start();
      }}
    />
  </div>
);

export default UtilizeOurTypeWriter;
